import Image from "next/legacy/image";
import Link from "next/link";
import {useContext, useState, useEffect} from "react";
import {Row, Col} from "react-bootstrap";
import classNames from "classnames";
import {generateOneLinkURL} from "../../lib/appsflyerOneLink";

import logo from "../../public/images/logos/logo-white-a.svg";
import appStore from "../../public/images/tradeMark/appstore.png";
import playStore from "../../public/images/tradeMark/playstore.png";
import ActivationFlowModal from "../Shared/ActivationFlowModal/ActivationFlowModal";

import styles from "./Footer.module.scss";
import GlobalButterCMSContentContext from "../context/globalButterCMSContent";

export default function Footer() {
  const {global_footer} = useContext(GlobalButterCMSContentContext);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [oneLinkUrl, setOneLinkUrl] = useState("#");

  useEffect(() => {
    const url = generateOneLinkURL();
    setOneLinkUrl(url);
  }, []);

  const onHide = () => {
    setShowActivationModal(false);
  };

  const [product, company, follow, getInToutch] = global_footer?.reference;

  const handleAppStoreClick = () => {
    setShowActivationModal(true);
  };

  return (
    <div className={styles.footer}>
      <div className="container">
        <Row className={styles.footerContent}>
          <Col md={3} className={styles.column}>
            <div className={styles.footerLogo}>
              <Image src={logo} alt="Autonomy logo" width={160} height={35} />
            </div>
          </Col>
          <Col md={2} className={styles.column}>
            {company && (
              <>
                <div className={styles.footerTitle}>{company?.footer_item}</div>
                {company.reference.map((item, idx) => (
                  <div
                    key={`${company?.footer_item}-${idx}`}
                    className={styles.footerLink}
                  >
                    <Link
                      prefetch={false}
                      href={`${item.url_slug}`}
                      legacyBehavior={true}
                    >
                      <a
                        target={item.is_new_tab ? "_blank" : "_self"}
                        className={styles.link_text}
                      >
                        {item.footer_item}
                      </a>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </Col>
          <Col md={2} className={styles.column}>
            {product && (
              <>
                <div className={styles.footerTitle}>{product?.footer_item}</div>
                {product.reference.map((item, idx) => (
                  <div
                    key={`${product?.footer_item}-${idx}`}
                    className={styles.footerLink}
                  >
                    <Link
                      prefetch={false}
                      href={`${item.url_slug}`}
                      legacyBehavior={true}
                    >
                      <a
                        target={item.is_new_tab ? "_blank" : "_self"}
                        className={styles.link_text}
                      >
                        {item.footer_item}
                      </a>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </Col>
          <Col md={2} className={styles.column}>
            {getInToutch && (
              <>
                <div className={styles.footerTitle}>
                  {getInToutch?.footer_item}
                </div>
                {getInToutch.reference.map((item, idx) => (
                  <div
                    key={`${getInToutch?.footer_item}-${idx}`}
                    className={styles.footerLink}
                  >
                    <Link
                      prefetch={false}
                      href={`${item.url_slug}`}
                      legacyBehavior={true}
                    >
                      <a
                        target={item.is_new_tab ? "_blank" : "_self"}
                        className={styles.link_text}
                      >
                        {item.footer_item}
                      </a>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </Col>
          <Col md={3} className={styles.column}>
            {follow && (
              <div className={styles.footerTitle}>{follow?.footer_item}</div>
            )}
            <div className={styles.socialMediaGroup}>
              {follow?.reference.map((item) => (
                <div key={item.meta.id} className={styles.socialMeida}>
                  <a
                    href={item.url_slug}
                    target={item.is_new_tab ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    {item.icon && (
                      <Image
                        src={item.icon}
                        width={30}
                        height={30}
                        alt={item.footer_item}
                      />
                    )}
                  </a>
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <div className={styles.appStoreWrapper}>
          <div className="d-none d-lg-block">
            <button
              className={styles.appStoreBtn}
              onClick={handleAppStoreClick}
            >
              <Image src={appStore} alt="app Store" width={120} height={40} />
            </button>
          </div>
          <div className="d-block d-lg-none">
            <Link href={oneLinkUrl} legacyBehavior={true}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.appStoreLink}
              >
                <Image src={appStore} alt="app Store" width={120} height={40} />
              </a>
            </Link>
          </div>
          <div className="d-none d-lg-block">
            <button
              className={styles.appStoreBtn}
              onClick={handleAppStoreClick}
            >
              <Image src={playStore} alt="Play Store" width={140} height={40} />
            </button>
          </div>
          <div className="d-block d-lg-none">
            <Link href={oneLinkUrl} legacyBehavior={true}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.appStoreLink}
              >
                <Image
                  src={playStore}
                  alt="Play Store"
                  width={140}
                  height={40}
                />
              </a>
            </Link>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.footerBottomLeft}>
            <div className={styles.footerBottomItem}>
              &copy; {new Date().getFullYear()} Autonomy
            </div>
            <div className={styles.footerBottomItem}>All rights reserved.</div>
            <div
              className={classNames(styles.footerBottomItem, "mt-5 mt-md-0")}
            >
              Vehicle Images by
              <Link href="https://www.evoximages.com" legacyBehavior={true}>
                <a className={styles.copyright_link} rel="nofollow">
                  {` `}EVOXImages.com
                </a>
              </Link>
            </div>
          </div>
          <div className={styles.footerBottomRight}>
            <div className={styles.footerBottomItem}>
              <Link prefetch={false} href="/privacy" legacyBehavior={true}>
                Privacy Policy
              </Link>
            </div>
            <div className={styles.footerBottomItem}>
              <Link prefetch={false} href="/tos" legacyBehavior={true}>
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ActivationFlowModal show={showActivationModal} onHide={onHide} />
    </div>
  );
}
